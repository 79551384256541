import validate from "/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/nuxt/dist/pages/runtime/validate.js";
import referrer_45global from "/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/middleware/referrer.global.ts";
import manifest_45route_45rule from "/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  referrer_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/@aller/nuxt-allerservice-sdk/middleware/auth.ts")
}
import { default as _91_46_46_46slug_93UH7abbt0dOMeta } from "/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/[...slug].vue?macro=true";
import { default as checkoutQhnUdcKVd5Meta } from "/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/checkout.vue?macro=true";
import { default as cookie_45deklarationIW9A1OFcqHMeta } from "/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/cookie-deklaration.vue?macro=true";
import { default as glemt_45kodeorddRACKPIMSyMeta } from "/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/glemt-kodeord.vue?macro=true";
import { default as loginddrIYKOegqtMeta } from "/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/logind.vue?macro=true";
import { default as _91slug_93k82l55Fwb3Meta } from "/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/mitaller/[slug].vue?macro=true";
import { default as indexSMZPyhnQAFMeta } from "/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/mitaller/index.vue?macro=true";
import { default as nyt_45kodeord6mFbwmOHJjMeta } from "/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/nyt-kodeord.vue?macro=true";
import { default as opretGPGif73CGtMeta } from "/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/opret.vue?macro=true";
import { default as prereceiptxuMIT3YNxbMeta } from "/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/prereceipt.vue?macro=true";
import { default as reklamationmb0cfNJzlsMeta } from "/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/reklamation.vue?macro=true";
import { default as tak_45for_45din_45bestillingowFv9NYfbnMeta } from "/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/tak-for-din-bestilling.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93UH7abbt0dOMeta || {},
    component: () => import("/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/[...slug].vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/checkout.vue")
  },
  {
    name: "cookie-deklaration",
    path: "/cookie-deklaration",
    component: () => import("/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/cookie-deklaration.vue")
  },
  {
    name: "glemt-kodeord",
    path: "/glemt-kodeord",
    component: () => import("/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/glemt-kodeord.vue")
  },
  {
    name: "logind",
    path: "/logind",
    component: () => import("/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/logind.vue")
  },
  {
    name: "mitaller-slug",
    path: "/mitaller/:slug()",
    meta: _91slug_93k82l55Fwb3Meta || {},
    component: () => import("/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/mitaller/[slug].vue")
  },
  {
    name: "mitaller",
    path: "/mitaller",
    meta: indexSMZPyhnQAFMeta || {},
    component: () => import("/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/mitaller/index.vue")
  },
  {
    name: "nyt-kodeord",
    path: "/nyt-kodeord",
    component: () => import("/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/nyt-kodeord.vue")
  },
  {
    name: "opret",
    path: "/opret",
    component: () => import("/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/opret.vue")
  },
  {
    name: "prereceipt",
    path: "/prereceipt",
    component: () => import("/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/prereceipt.vue")
  },
  {
    name: "reklamation",
    path: "/reklamation",
    component: () => import("/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/reklamation.vue")
  },
  {
    name: "tak-for-din-bestilling",
    path: "/tak-for-din-bestilling",
    component: () => import("/codebuild/output/src1410534282/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/tak-for-din-bestilling.vue")
  }
]